const initialState = {
  dataAddTaskAccept: {},
  loadingAddTaskAccept: false,
  statusAddTaskAccept: 0,
  dataAddTaskImage: {},
  loadingAddTaskImage: false,
  statusAddTaskImage: 0,
  dataDeleteTaskImage: {},
  loadingDeleteTaskImage: false,
  statusDeleteTaskImage: 0,
  dataUpdateOther: {},
  loadingUpdateOther: false,
  statusUpdateOther: 0,
};

export default initialState;
