export const RESET_TASK = "RESET_TASK";

/* (ASYNC) */
export const GET_TASK_INIT = "GET_TASK_INIT";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

export const RESET_ADD_TASK_ASSIGN = "RESET_ADD_TASK_ASSIGN";

/* (ASYNC) */
export const ADD_TASK_ASSIGN_INIT = "ADD_TASK_ASSIGN_INIT";
export const ADD_TASK_ASSIGN_SUCCESS = "ADD_TASK_ASSIGN_SUCCESS";
export const ADD_TASK_ASSIGN_FAILURE = "ADD_TASK_ASSIGN_FAILURE";

export const RESET_PDF = "RESET_PDF";

/* (ASYNC) */
export const GET_PDF_INIT = "GET_PDF_INIT";
export const GET_PDF_SUCCESS = "GET_PDF_SUCCESS";
export const GET_PDF_FAILURE = "GET_PDF_FAILURE";

export const RESET_DELETE_TASK = "RESET_DELETE_TASK";

/* (ASYNC) */
export const DELETE_TASK_INIT = "DELETE_TASK_INIT";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

export const RESET_TASK_COUNT = "RESET_TASK_COUNT";

/* (ASYNC) */
export const GET_TASK_COUNT_INIT = "GET_TASK_COUNT_INIT";
export const GET_TASK_COUNT_SUCCESS = "GET_TASK_COUNT_SUCCESS";
export const GET_TASK_COUNT_FAILURE = "GET_TASK_COUNT_FAILURE";
