export const RESET_HOME = "RESET_HOME";
export const RESET_ACTIVE_COMPANY = "RESET_ACTIVE_COMPANY";

/* (ASYNC) */
export const GET_HOME_INIT = "GET_HOME_INIT";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_FAILURE = "GET_HOME_FAILURE";

export const ACTIVE_COMPANY_INIT = "ACTIVE_COMPANY_INIT";
export const ACTIVE_COMPANY_SUCCESS = "ACTIVE_COMPANY_SUCCESS";
export const ACTIVE_COMPANY_FAILURE = "ACTIVE_COMPANY_FAILURE";
