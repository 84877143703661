import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Home = (props) => (
  <li>
    <Link
      to="/dashboard/instalation"
      id="menu-100"
      data-menu="ok"
      className={`${props.mobile}`}
      onClick={props.handleMenu}
      onKeyDown={props.handleMenu}
    >
      <i className="far fa-clock"></i>
      <strong>
        <FormattedMessage id="InstallationInProcess" />
      </strong>
    </Link>
  </li>
);

export default Home;
