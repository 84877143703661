export const RESET_FILTERS_INSTALADOR = "RESET_FILTERS_INSTALADOR";
export const RESET_FILTERS_CONTAINER = "RESET_FILTERS_CONTAINER";
export const RESET_FILTERS_SITIO = "RESET_FILTERS_SITIO";
export const RESET_FILTERS_PRODUCT = "RESET_FILTERS_PRODUCT";
export const RESET_FILTERS_HOURS = "RESET_FILTERS_HOURS";

/* (ASYNC) */
export const GET_FILTERS_INSTALADOR_INIT = "GET_FILTERS_INSTALADOR_INIT";
export const GET_FILTERS_INSTALADOR_SUCCESS = "GET_FILTERS_INSTALADOR_SUCCESS";
export const GET_FILTERS_INSTALADOR_FAILURE = "GET_FILTERS_INSTALADOR_FAILURE";

export const GET_FILTERS_CONTAINER_INIT = "GET_FILTERS_CONTAINER_INIT";
export const GET_FILTERS_CONTAINER_SUCCESS = "GET_FILTERS_CONTAINER_SUCCESS";
export const GET_FILTERS_CONTAINER_FAILURE = "GET_FILTERS_CONTAINER_FAILURE";

export const GET_FILTERS_SITIO_INIT = "GET_FILTERS_SITIO_INIT";
export const GET_FILTERS_SITIO_SUCCESS = "GET_FILTERS_SITIO_SUCCESS";
export const GET_FILTERS_SITIO_FAILURE = "GET_FILTERS_SITIO_FAILURE";

export const GET_FILTERS_PRODUCT_INIT = "GET_FILTERS_PRODUCT_INIT";
export const GET_FILTERS_PRODUCT_SUCCESS = "GET_FILTERS_PRODUCT_SUCCESS";
export const GET_FILTERS_PRODUCT_FAILURE = "GET_FILTERS_PRODUCT_FAILURE";

export const GET_FILTERS_HOURS_INIT = "GET_FILTERS_HOURS_INIT";
export const GET_FILTERS_HOURS_SUCCESS = "GET_FILTERS_HOURS_SUCCESS";
export const GET_FILTERS_HOURS_FAILURE = "GET_FILTERS_CONTAINER_FAILURE";

export const SET_ID_CLIENT = "SET_ID_CLIENT";
export const SET_ID_PRODUCT = "SET_ID_PRODUCT";
