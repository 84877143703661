const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataActiveCompany: {},
  loadingActiveCompany: false,
  statusActiveCompany: 0,
};

export default initialState;
