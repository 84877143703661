import {
  GET_LOGIN_INIT,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  RESET_LOGIN,
  GET_FORGET_PASS_INIT,
  GET_FORGET_PASS_SUCCESS,
  GET_FORGET_PASS_FAILURE,
  RESET_FORGET_PASS,
  POST_CHANGE_PASS_INIT,
  POST_CHANGE_PASS_SUCCESS,
  POST_CHANGE_PASS_FAILURE,
  RESET_CHANGE_PASS,
  POST_CHANGE_LANGUAGE_INIT,
  POST_CHANGE_LANGUAGE_SUCCESS,
  POST_CHANGE_LANGUAGE_FAILURE,
  RESET_CHANGE_LANGUAGE,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetLogin() {
  return {
    type: RESET_LOGIN,
  };
}

/* Get Login (Async) */

function getLoginSuccess(data) {
  return {
    type: GET_LOGIN_SUCCESS,
    payload: data,
  };
}

function getLoginFailure(error) {
  return {
    type: GET_LOGIN_FAILURE,
    payload: error,
  };
}

function getLoginInit() {
  return {
    type: GET_LOGIN_INIT,
  };
}

export function getLogin(data) {
  return async (dispatch) => {
    dispatch(getLoginInit());
    try {
      const resp = await API.data.getLogin(data);
      return dispatch(getLoginSuccess(resp));
    } catch (error) {
      return dispatch(getLoginFailure(error));
    }
  };
}

export function resetForgetPass() {
  return {
    type: RESET_FORGET_PASS,
  };
}

/* Get ForgetPass (Async) */

function getForgetPassSuccess(data) {
  return {
    type: GET_FORGET_PASS_SUCCESS,
    payload: data,
  };
}

function getForgetPassFailure(error) {
  return {
    type: GET_FORGET_PASS_FAILURE,
    payload: error,
  };
}

function getForgetPassInit() {
  return {
    type: GET_FORGET_PASS_INIT,
  };
}

export function getForgetPass(data) {
  return async (dispatch) => {
    dispatch(getForgetPassInit());
    try {
      const resp = await API.data.getForgetPass(data);
      return dispatch(getForgetPassSuccess(resp));
    } catch (error) {
      return dispatch(getForgetPassFailure(error));
    }
  };
}

export function resetChangePass() {
  return {
    type: RESET_CHANGE_PASS,
  };
}

/* Get ForgetPass (Async) */

function postChangePassSuccess(data) {
  return {
    type: POST_CHANGE_PASS_SUCCESS,
    payload: data,
  };
}

function postChangePassFailure(error) {
  return {
    type: POST_CHANGE_PASS_FAILURE,
    payload: error,
  };
}

function postChangePassInit() {
  return {
    type: POST_CHANGE_PASS_INIT,
  };
}

export function postChangePass(data) {
  return async (dispatch) => {
    dispatch(postChangePassInit());
    try {
      const resp = await API.data.postChangePass(data);
      return dispatch(postChangePassSuccess(resp));
    } catch (error) {
      return dispatch(postChangePassFailure(error));
    }
  };
}

export function resetChangeLanguage() {
  return {
    type: RESET_CHANGE_LANGUAGE,
  };
}

/* Get ForgetLanguage (Async) */

function postChangeLanguageSuccess(data) {
  return {
    type: POST_CHANGE_LANGUAGE_SUCCESS,
    payload: data,
  };
}

function postChangeLanguageFailure(error) {
  return {
    type: POST_CHANGE_LANGUAGE_FAILURE,
    payload: error,
  };
}

function postChangeLanguageInit() {
  return {
    type: POST_CHANGE_LANGUAGE_INIT,
  };
}

export function postChangeLanguage(data) {
  return async (dispatch) => {
    dispatch(postChangeLanguageInit());
    try {
      const resp = await API.data.postChangeLanguage(data);
      return dispatch(postChangeLanguageSuccess(resp));
    } catch (error) {
      return dispatch(postChangeLanguageFailure(error));
    }
  };
}
