export const RESET_TASK_ID = "RESET_TASK_ID";

/* (ASYNC) */
export const GET_TASK_ID_INIT = "GET_TASK_ID_INIT";
export const GET_TASK_ID_SUCCESS = "GET_TASK_ID_SUCCESS";
export const GET_TASK_ID_FAILURE = "GET_TASK_ID_FAILURE";

export const RESET_UPDATE_TASK = "RESET_UPDATE_TASK";

/* (ASYNC) */
export const UPDATE_TASK_INIT = "UPDATE_TASK_INIT";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";
