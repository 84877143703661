import initialState from "./initialState";
import {
  GET_LOGIN_INIT,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  GET_FORGET_PASS_INIT,
  GET_FORGET_PASS_SUCCESS,
  GET_FORGET_PASS_FAILURE,
  POST_CHANGE_PASS_INIT,
  POST_CHANGE_PASS_SUCCESS,
  POST_CHANGE_PASS_FAILURE,
  POST_CHANGE_LANGUAGE_INIT,
  POST_CHANGE_LANGUAGE_SUCCESS,
  POST_CHANGE_LANGUAGE_FAILURE,
  RESET_LOGIN,
  RESET_FORGET_PASS,
  RESET_CHANGE_PASS,
  RESET_CHANGE_LANGUAGE,
} from "../../actions/login/types";

export default function login(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_LOGIN:
      localStorage.clear();
      return {
        ...state,
        data: false,
        loading: false,
        status: 0,
      };
    case GET_LOGIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_SUCCESS:
      const auth = action.payload.status === 200 ? true : false;
      if (auth) {
        localStorage.setItem("auth", auth);
        localStorage.setItem("names", action.payload.data.names);
      }
      return {
        ...state,
        data: auth,
        loading: false,
        status: action.payload.status,
      };
    case GET_LOGIN_FAILURE:
      return {
        ...state,
        data: true,
        loading: false,
        status: 501,
      };
    case RESET_FORGET_PASS:
      return {
        ...state,
        dataForgetPass: false,
        loadingForgetPass: false,
        statusForgetPass: 0,
      };
    case GET_FORGET_PASS_INIT:
      return {
        ...state,
        loadingForgetPass: true,
      };
    case GET_FORGET_PASS_SUCCESS:
      return {
        ...state,
        dataForgetPass: action.payload.data,
        loadingForgetPass: false,
        statusForgetPass: action.payload.status,
      };
    case GET_FORGET_PASS_FAILURE:
      return {
        ...state,
        dataForgetPass: false,
        loadingForgetPass: false,
        statusForgetPass: 501,
      };
    case RESET_CHANGE_PASS:
      return {
        ...state,
        dataChangePass: {},
        loadingChangePass: false,
        statusChangePass: 0,
      };
    case POST_CHANGE_PASS_INIT:
      return {
        ...state,
        loadingChangePass: true,
      };
    case POST_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        dataChangePass: action.payload.data,
        loadingChangePass: false,
        statusChangePass: action.payload.status,
      };
    case POST_CHANGE_PASS_FAILURE:
      return {
        ...state,
        dataChangeLanguage: false,
        loadingChangeLanguage: false,
        statusChangeLanguage: 501,
      };
    case RESET_CHANGE_LANGUAGE:
      return {
        ...state,
        dataChangeLanguage: {},
        loadingChangeLanguage: false,
        statusChangeLanguage: 0,
      };
    case POST_CHANGE_LANGUAGE_INIT:
      return {
        ...state,
        loadingChangeLanguage: true,
      };
    case POST_CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        dataChangeLanguage: action.payload.data,
        loadingChangeLanguage: false,
        statusChangeLanguage: action.payload.status,
      };
    case POST_CHANGE_LANGUAGE_FAILURE:
      return {
        ...state,
        dataChangeLanguage: false,
        loadingChangeLanguage: false,
        statusChangeLanguage: 501,
      };
    default:
      return state;
  }
}
