export const RESET_ADD_TASK_ACCEPT = "RESET_ADD_TASK_ACCEPT";

/* (ASYNC) */
export const ADD_TASK_ACCEPT_INIT = "ADD_TASK_ACCEPT_INIT";
export const ADD_TASK_ACCEPT_SUCCESS = "ADD_TASK_ACCEPT_SUCCESS";
export const ADD_TASK_ACCEPT_FAILURE = "ADD_TASK_ACCEPT_FAILURE";

export const RESET_ADD_TASK_IMAGE = "RESET_ADD_TASK_IMAGE";

/* (ASYNC) */
export const ADD_TASK_IMAGE_INIT = "ADD_TASK_IMAGE_INIT";
export const ADD_TASK_IMAGE_SUCCESS = "ADD_TASK_IMAGE_SUCCESS";
export const ADD_TASK_IMAGE_FAILURE = "ADD_TASK_IMAGE_FAILURE";

export const RESET_DELETE_TASK_IMAGE = "RESET_DELETE_TASK_IMAGE";

/* (ASYNC) */
export const DELETE_TASK_IMAGE_INIT = "DELETE_TASK_IMAGE_INIT";
export const DELETE_TASK_IMAGE_SUCCESS = "DELETE_TASK_IMAGE_SUCCESS";
export const DELETE_TASK_IMAGE_FAILURE = "DELETE_TASK_IMAGE_FAILURE";

export const RESET_UPDATE_OTHER = "RESET_UPDATE_OTHER";

/* (ASYNC) */
export const UPDATE_OTHER_INIT = "UPDATE_OTHER_INIT";
export const UPDATE_OTHER_SUCCESS = "UPDATE_OTHER_SUCCESS";
export const UPDATE_OTHER_FAILURE = "UPDATE_OTHER_FAILURE";
