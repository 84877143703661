const initialState = {
  dataTaskId: {},
  loadingTaskId: true,
  statusTaskId: 0,
  dataUpdateTask: {},
  loadingUpdateTask: false,
  statusUpdateTask: 0,
};

export default initialState;
