import axios from "axios";

const API = {
  data: {
    async getLogin(obj) {
      const response = await axios.post(`/api/login`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getForgetPass(obj) {
      const response = await axios.get(
        `/api/login/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;
      return {
        data,
        status,
      };
    },
    async postChangePass(obj) {
      const response = await axios.post(
        `/api/loginPass/${encodeURIComponent(JSON.stringify(obj))}`,
        {}
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async postChangeLanguage(obj) {
      const response = await axios.post(
        `/api/loginLanguage/${encodeURIComponent(JSON.stringify(obj))}`,
        {}
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
