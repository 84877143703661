export const RESET_LOGIN = "RESET_LOGIN";
export const RESET_FORGET_PASS = "RESET_FORGET_PASS";
export const RESET_CHANGE_PASS = "RESET_CHANGE_PASS";
export const RESET_CHANGE_LANGUAGE = "RESET_CHANGE_LANGUAGE";
/* (ASYNC) */
export const GET_LOGIN_INIT = "GET_LOGIN_INIT";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";

export const GET_FORGET_PASS_INIT = "GET_FORGET_PASS_INIT";
export const GET_FORGET_PASS_SUCCESS = "GET_FORGET_PASS_SUCCESS";
export const GET_FORGET_PASS_FAILURE = "GET_FORGET_PASS_FAILURE";

export const POST_CHANGE_PASS_INIT = "POST_CHANGE_PASS_INIT";
export const POST_CHANGE_PASS_SUCCESS = "POST_CHANGE_PASS_SUCCESS";
export const POST_CHANGE_PASS_FAILURE = "POST_CHANGE_PASS_FAILURE";

export const POST_CHANGE_LANGUAGE_INIT = "POST_CHANGE_LANGUAGE_INIT";
export const POST_CHANGE_LANGUAGE_SUCCESS = "POST_CHANGE_LANGUAGE_SUCCESS";
export const POST_CHANGE_LANGUAGE_FAILURE = "POST_CHANGE_LANGUAGE_FAILURE";
