import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

/* Actions  */
import { postChangeLanguage, resetChangeLanguage } from "../../actions/login";

function ModalChangeLanguage(props) {
  /*  Use  states  */
  const [form, setForm] = useState({
    language: props.language,
  });

  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const closeModal = () => {
    props.setModal(false);
  };
  const postChange = (event) => {
    event.preventDefault();
    localStorage.setItem("language", form.language);
    dispatch(
      postChangeLanguage({
        form,
      })
    );
    dispatch(resetChangeLanguage());
    closeModal();
  };

  let error = "";
  if (!state.dataChangeLanguage) {
    error = (
      <p className="text-error">
        <FormattedMessage id="ThereWasAnError" />
      </p>
    );
  }

  if (state.statusChangeLanguage === 200) {
    error = (
      <h3 className="text-success">
        <FormattedMessage id="ChangeLanguageSuccessful" />
      </h3>
    );
  }
  return (
    <>
      <Modal
        show={props.modal}
        onHide={closeModal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="ChangeMyLanguageword" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id="Languages" />
            </Form.Label>
            <Form.Control
              as="select"
              value={form.language}
              onChange={handleChange}
              name="language"
              data-number="ok"
            >
              <FormattedMessage id="Spanish">
                {(message) => <option value="es">{message}</option>}
              </FormattedMessage>
              <FormattedMessage id="English">
                {(message) => <option value="en">{message}</option>}
              </FormattedMessage>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {error}
          <Button
            disabled={state.loadingChangeLanguage}
            variant="main"
            type="submit"
            onClick={postChange}
          >
            {state.loadingChangeLanguage ? (
              <FormattedMessage id="Loading" />
            ) : (
              <FormattedMessage id="Send" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalChangeLanguage;
