const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataAddTaskAssign: {},
  loadingAddTaskAssign: false,
  statusAddTaskAssign: 0,
  dataPdf: {},
  loadingPdf: true,
  statusPdf: 0,
  dataDeleteTask: {},
  loadingDeleteTask: false,
  statusDeleteTask: 0,
  dataCount: {},
  loadingCount: true,
  statusCount: 0,
};

export default initialState;
